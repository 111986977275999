import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import {
	FaAngellist,
	FaFileImport,
	FaGoogle,
	FaInstagram,
	FaLanguage,
	FaListUl,
} from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { IoIosHelpBuoy, IoIosSpeedometer, IoMdPaper } from "react-icons/io";
import { MdBrush, MdOndemandVideo } from "react-icons/md";
import Faq from "../../components/faq";
import Layout from "../../components/layout";
import SeoHero from "../../components/seo-hero";
import SeoMapSection from "../../components/seo-map-section";
import WhyChooseRJMSection from "../../components/why-choose-rjm-section";

const RealEstateWebDesignAbuDhabi = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			needHelpImage: wpMediaItem(
				title: { eq: "Real estate web design Abu Dhabi" }
			) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			RebImage2: wpMediaItem(title: { eq: "REB-Image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Real Estate Web Design Abu Dhabi" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			flexImage: wpMediaItem(title: { eq: "Flex MasterLogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ryanImage: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Real Estate Web Design Abu Dhabi",
				item: {
					url: `${siteUrl}/dubai/real-estate-web-design-abu-dhabi`,
					id: `${siteUrl}/dubai/real-estate-web-design-abu-dhabi`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/real-estate-web-design-abu-dhabi`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<SeoHero
						page="Real Estate WEB DESIGN Abu Dhabi"
						dubai
						title={`Real Estate \n<span class="text-secondary">WEB DESIGN</span> \nAbu Dhabi`}
						description={
							<span>
								Building you a bespoke website that attracts and converts your
								ideal buyers and sellers.
							</span>
						}
					/>
				</section>

				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Do you want a website that attracts and engages your target
									clients?
								</h2>
								<p>
									RJM Digital will transform your real estate agency into one of
									Abu Dhabi’s highly sought-after brokers with a new website
									purpose built for your agency.
								</p>
								<p>
									We firmly believe that creating your own personalised website
									will:
								</p>

								<ul className="higher">
									<li>
										Enhance your agency's reputation with a website that
										reflects your brand identity.
									</li>
									<li>
										Efficiently manage your property listings on a personalised
										platform tailored to your agency's needs.
									</li>
									<li>
										Achieve improved outcomes by implementing our effective
										digital marketing strategies such as SEO to attract a
										diverse range of buyers and sellers previously beyond your
										reach.
									</li>
								</ul>
								<p>
									Our website solution is specifically designed for real estate
									agencies, allowing you to present your listings in unique
									ways, showcase client testimonials, create targeted landing
									pages for your marketing campaigns and add even more value to
									your buyers and sellers.
								</p>

								<Button
									className=" w-100 cta-btn w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
							<Col lg={5}>
								<div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.needHelpImage.gatsbyImage}
										alt={data.needHelpImage.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center g-5 g-lg-7">
							<Col xs={6} lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.flexofficesolutions.co.uk/"
								>
									<GatsbyImage
										image={data.flexImage.gatsbyImage}
										alt={data.flexImage.altText}
										class="w-100"
									/>
								</a>
							</Col>
							<Col xs={6} lg={4}>
								<GatsbyImage
									image={data.ryanImage.gatsbyImage}
									alt={data.ryanImage.altText}
									class="w-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(49deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className=" py-5 py-lg-7"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 text-white text-center mb-5 mb-lg-7">
									Key features of our real estate websites
								</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdBrush className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Build your brand</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Create your own brand which represents your values and
									connects with your ideal clients.
								</p>
							</Col>

							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaFileImport className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Import listings</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Import your listings quick and easy and publish directly to
									your site.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoMdPaper className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">
									Project Landing Pages
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Add your own knowledge and expertise to property developments
									across Abu Dhabi.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaListUl className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Shortlist</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Personalise the approach of clients choosing the properties
									they want to view and your subsequent recommendations.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaInstagram className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Sync your socials</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Highlight your content by syncing all your social media
									channels.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosSpeedometer className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Ultra-Fast</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Have each page load in less than one second for the ultimate
									user experience.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaGoogle className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Built for SEO</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Be seen on google when people are searching for particular
									developments or projects.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosHelpBuoy className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Fully Managed</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We will maintain, support and secure your site so you need not
									worry.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaAngellist className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Featured Listings</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Grab the eye of your buyers by highlighting your featured
									projects, listings or developments.
								</p>
							</Col>

							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiDocumentText className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Upload useful docs</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Make the site more valuable to your clients with info that’s
									not widely accessible elsewhere.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdOndemandVideo className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Virtual Tours</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Increase interaction with users by uploading virtual tours and
									YouTube videos to your listings.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaLanguage className="display-3 text-white" />
								<h3 className="ssp-bold text-white my-4">Multiple languages</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Build trust and engagement with your customers by using their
									preferred language.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className=" py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6 gx-xl-7">
							<Col lg={6}>
								<div
									className="mb-5 mb-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={
											data.RebImage2.gatsbyImage
										}
										alt={data.RebImage2.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className=" ">
								<h2 className="fs-1 text-primary mb-5">
									Why building your personal brand is the key to real estate
									success
								</h2>
								<p>
									A personal brand is a reflection of what you stand for in the
									industry and helps you to differentiate yourself from all the
									other brokers in Dubai. Your brand should echo how you
									interact with buyers and sellers in your day-to-day life, so
									it's important to be clear about the values you hold and how
									you want to be perceived by your clients.
								</p>
								<p>
									Having a strong personal brand can help you attract new
									clients, build relationships with existing ones, and increase
									your visibility. People are more likely to trust and work with
									someone they know, like and can rely on. Therefore, having a
									website that can illustrate this 24/7 will establish yourself
									as an expert in the Dubai real estate industry.
								</p>
							</Col>
						</Row>
					</Container>
				</section> */}
				{/* <section className="py-5 py-lg-7">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row>
							<Col>
								<h2 className="text-primary display-5  text-center pb-5">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								dubai
								btnColour="secondary"
								title="Essential"
								subText="Real Estate Brokers building their first website"
								price="AED495"
								monthly
								setupFee="AED5000"
								features={[
									"Templated design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully Managed Website{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"GatsbyJS frontend",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Listings Manager{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Add, edit and remove listings via our Listings
															management tool
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"WordPress Content Management System",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											MapBox Integration{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Involves a usage charge billed directly from
															MapBox
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Unlimited Images, Audio and File content",
									"Fast and Secure Web Hosting",
									"SSL Certificate",
									"Custom Forms",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								dubai
								btnColour="dark-blue"
								middle
								title="Premium"
								subText={
									<span>
										Real Estate Brokers looking for a bespoke solution
									</span>
								}
								price="AED995"
								monthly
								setupFee="AED12500"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Essential</span>
									</span>,
									"Bespoke design",
									"SEO advice and basic implementation",
									"Monthly Analytics Report",
									"Shortlist functionality",
									"Monthly testing and updates on new browser / OS updates",
									"Lead capture forms and e-mail notifications",
									<span>
										Add Branding Kit for{" "}
										<span className="ssp-bold text-primary">AED 2500</span>
									</span>,
								]}
							/>
							<PricingCard
								dubai
								btnColour="light-blue"
								title="Pro"
								subText="Real Estate Brokers looking for an advanced portal"
								price="AED1495"
								monthly
								setupFee="AED20000"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Premium</span>
									</span>,
									"Fully customisable listings engine",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Login section for gated content{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Maximum user limits apply
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Multilingual functionality{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Translator costs charged separately on ad-hoc
															basis
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"Social media feed sync",
									"Integration with your CRM",
									"Progressive Web App",
									"Advanced SEO advice and implementation",
									"Conversion Rate Optimisation recommendations each month",
								]}
							/>
						</Row>
					</Container>
				</section> */}
				<WhyChooseRJMSection
					experienced="You need a specialist team to bring you new leads that you demand from your website. At RJM Digital we have over 5 years experience in the industry and have delivered a proven platform for real estate brokers."
					bespoke="Each and every one of our websites is different and unique to the individual customer it was made for. We strive to create websites that stand out from the crowd."
					provenResults="Over the years we have continually delivered results for our customers and our testimonials highlight this. Most marketing agencies say they can deliver but don’t have the evidence to back it up."
					reliable="Throughout your whole partnership with RJM Digital, we will always be by your side for any help or assistance needed."
					transparent="At every step of the way we will be open in everything that we do, even if things don’t go as planned. We will always be available for a call or meeting to answer any queries you may have."
					trustworthy="By keeping to our core values of being reliable, transparent and results-driven, we are proud to have built long-lasting relationships with 50+ customers from around the world."
				/>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					className="py-5 py-lg-7"
					style={{
						background:
							"transparent linear-gradient(76deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
				>
					<Container>
						<Row>
							<Col className="text-center">
								<h2 className="text-white mb-4">
									Are you a Real Estate Agency
									<br className="d-none d-lg-block" /> looking to build a
									portal?
								</h2>
								<p className="text-white">
									Create your own bespoke solution with our Real Estate Agency
									Web design solution
								</p>
								<Button
									className=" w-100 cta-btn w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									as={Link}
									to="/dubai/real-estate-web-design-dubai"
								>
									Find out more
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14454.8950719762!2d55.1474062!3d25.07735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6d5f7304c865%3A0xa891c5ac1cc02331!2sRJM%20Digital!5e0!3m2!1sen!2suk!4v1676375997015!5m2!1sen!2suk" />

				<div id="faq">
					<Faq page="Real Estate Brokers Dubai" />
				</div>
				{/* <OSSection
					dubai
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default RealEstateWebDesignAbuDhabi;
